import React from "react";
import { graphql, withPrefix } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";

import { useLanguage } from "../components/LanguageSelect";

import contactUsStyle from "./contact.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "contact" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            name
            email
            send
            country
            describeProject
            header {
              title
              subtitle
            }
            sections {
              section {
                id
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const language = useLanguage();
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const {
    header,
    sections,
    send,
    country,
    email,
    name,
    describeProject,
  } = pageContent;
  const section1 = sections.filter((element) => element.section.id === "1")[0]
    .section;
  const section2 = sections.filter((element) => element.section.id === "2")[0]
    .section;
  const section3 = sections.filter((element) => element.section.id === "3")[0]
    .section;
  const section4 = sections.filter((element) => element.section.id === "4")[0]
    .section;

  const heading = (
    <Heading>
      <div className={contactUsStyle.contactusTitle}>
        <h1>{header.title}</h1>
        <h3>{header.subtitle}</h3>
      </div>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />

      <div className={contactUsStyle.formContainer}>
        <div className={contactUsStyle.description}>
          <h1>{section1.title}</h1>
          <p className={contactUsStyle.subtitle}>{section1.description}</p>
          <p>{section2.description}</p>
          <p>{section3.description}</p>
          <div className={contactUsStyle.info}>
            <h2>{section4.title}</h2>
            <p>{section4.description}</p>
          </div>
        </div>
        <form
          name="Contact Form"
          method="POST"
          data-netlify-recaptcha="true"
          data-netlify="true"
          action={withPrefix(`${language}/contact-thanks`)}
          className={contactUsStyle.form}
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div>
            <label>{name}</label>
            <input type="name" name="name" />
          </div>
          <div>
            <label>{email}</label>
            <input type="email" name="email" />
          </div>
          <div>
            <label>{country}</label>
            <input type="country" name="country" />
          </div>
          <div>
            <label>{describeProject}</label>
            <textarea name="message" />
          </div>
          <ReCAPTCHA sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY} />
          <button type="submit">{send}</button>
        </form>
      </div>
    </Layout>
  );
};

export default React.memo(ContactPage);
